a{
    text-decoration: none;
}

h3{
    font-size: 32px;
    color: #54abc9;
}
.cta{
    color: white;
}

.offer{
    background: linear-gradient(174deg, #b2262a, #e64a2c);
    width: 2.4vw;
    height: 11vw;
    writing-mode: vertical-lr;
    transform: scale(-1);
    font-size: 2.2vw;
    position: fixed;
    top: 200px;
    right: 0;
    align-items: center;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    padding: 60px 15px;
    @media (min-width: 1400px) {
        font-size: 30px;
        width: 34px;
        height: 154px;

    }
    @media (max-width: 768px) {
        font-size: 14px;
        width: 19px;
        height: 85px;
        padding: 20px 5px;
    }
}

.service-cta {
    background: linear-gradient(174deg, #b2262a, #e64a2c);
    font-size: 1.5rem;
    align-items: center;
    border-radius: 20px;
    margin-top: 40px;
    padding: 15px 15px;
}

.message-button{
    background: linear-gradient(174deg, #b2262a, #e64a2c);
    font-size: 1.5rem;
    align-items: center;
    border-radius: 20px;
    padding: 15px 15px;
    outline: none;
    border: none; /* Ha van alapértelmezett border, ezt eltünteti */
    box-shadow: none;
}

.pointer{
    cursor: pointer;
}

.section-service {
    display: flex;
    flex-direction: row;
    height: 100vh;
    gap: 20px;
    padding: 20px;
}

.service-item {
    text-align: center;
    /*background-color: #f0f0f0;*/
    /*width: 100%;*/
    /*margin: 20px;*/
    padding: 20px;
    /*border-radius: 8px;*/
    /*box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);*/
    @media (max-width: 400px) {
        margin: 0;
        padding: 0;
    }
}

.blog-item {
    text-align: center;
    padding: 20px;
    @media (max-width: 400px) {
        margin: 0;
        padding: 0;
    }
}

.blog-item:hover{
    background-color: #eef7fa;
    border-radius: 20px;
}

.logo{
    width: 400px;
    align-self: center;
    border-radius: 20px;
    @media (max-width: 400px) {
        width: 300px;
    }
}

.center{
    display: flex;
    align-items: center;
}

.blog-image{
    margin: auto;
    width: 400px;
    align-self: center;
    border-radius: 20px;
    @media (max-width: 400px) {
        width: 300px;
    }
}
.icon{
    width: 50px;
    border-radius: 8px;

}

.service-icon {
    align-content: center;
    margin-bottom: 30px;
}

.progress-bar {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    height: 7px;
    background: #54abc9; /*#b2262a  #e64a2c     */
    transform-origin: 0%;
    z-index: 200;
}

.header {
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 100;
    /*background: #54abc9;*/
    color: white;
}

.footer-widgets__list{
    list-style-type: none;
    color: white;
    padding: 0;
    margin: 10px;
}

.footer-widgets__list li{
    display: flex;
    padding: 0;
    margin: 10px;
}

.footer-widgets__list a{
    color: white;
}

.MuiFormControlLabel-root .Mui-required {
    color: white; /* Válaszd ki a kívánt színt */
}

.hero-container{
    margin-top: 100px;
    margin-bottom: 100px;
}

.hero1{
    color: #54abc9;
}

.hero-logo{
    display: flex;
    @media (max-width: 400px) {
        display: none;
    }
}

.hero2{
    padding-bottom: 20px;
    padding-top: 20px;
}
.hero2 h2{
    font-size: 50px;
    font-weight: bold;
    @media (max-width: 400px) {
        font-size: 40px;
    }
}

.faq{
    padding-bottom: 20px;
    padding-top: 20px;
}
.faq h2{
    font-size: 50px;
    font-weight: bold;
    @media (max-width: 400px) {
        font-size: 40px;
    }
}

.service p{
    font-size: 24px;
    @media (max-width: 400px) {
        font-size: 16px;
    }
}

.blog h2{
    font-size: 50px;
    font-weight: bold;
    color: #54abc9;
}


.blog-page{
    margin-top: 70px;
}

.blog-page h1{
    font-size: 48px;
    @media (max-width: 400px) {
        font-size: 34px;
    }
}

.blog-page h3{
    font-size: 34px;
    @media (max-width: 400px) {
        font-size: 24px;
    }
}

.post h1 {
    font-size: 3.75rem;
}

.post h2 {
    color: #54abc9;
    margin: 0;
    font-size: 1.5625rem;
}

.post h3 {
    margin: 0;
    font-size: 1.5625rem;
}

.post h4 {
    color: #54abc9;
    margin: 30px 0 0 0;
    font-size: 1.5625rem;
}

.post-image {
    margin-top: 50px;
    margin-bottom: 50px;
}
.post-headline {
    margin-top: 40px;
}

.post-content p{
    font-size: 1.1875rem;
    line-height: 1.44em;
}

.blog p{
    color: #1f1f1f;
    font-size: 1.1875rem;
    line-height: 1.44em;
}

.page {
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    flex: 1;
}



@media (max-width: 400px) {
    .post h1 {
        font-size: 1.875rem;
    }

    .post h2 {
        font-size: 1.375rem;
    }

    .post h3 {
        font-size: 1.375rem;
    }

    .post h4 {
        font-size: 1.375rem;
    }
    .post-content p{
        font-size: 1.0625rem;
    }
    .mobile-half{
        width: 50%;
        height: 50%;
    }
}

@media (min-width: 401px) and (max-width: 900px) {
    .post h1 {
        font-size: 2.5rem;
    }

    .post h2 {
        font-size: 1.475rem;
    }

    .post h3 {
        font-size: 1.475rem;
    }

    .post h4 {
        font-size: 1.475rem;
    }
    .post-content p{
        font-size: 1.125rem;
    }
    .mobile-half{
        width: 75%;
        height: 75%;
    }
}

.bold {
    font-weight: bold;
}

.old-style {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    margin: 20px 0;
    padding: 20px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
}

.cookie-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    padding: 1rem;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.cookie-options label {
    display: block;
    margin-bottom: 0.5rem;
}

button {
    margin-right: 1rem;
}

.time-btn{
    width: 100px;
    height: 60px;
    border:  1px solid #54abc9;
}

.book-btn{
    width: 250px;
    height: 60px;
    border:  1px solid #54abc9;
}

/* slider */
.card-slider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

.cards-container {
    display: flex;
    overflow: hidden;
    width: 100%;
    /*margin: 0 10px;*/
}

.card {
    flex: 0 0 calc(93% / 3); /* Asztali nézethez */
    margin: 0 10px;
    background: #eef7fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    text-align: center;
    transition: transform 0.3s ease;
}

.card-img {
    flex: 0 0 calc(95%); /* Asztali nézethez */
    /*margin: 0 10px;
    background: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;*/
    text-align: center;
    transition: transform 0.3s ease;
}

.card-slider button {
    background: #54abc9;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.card-slider button:disabled {
    background: #cccccc;
    cursor: not-allowed;
}

.card-slider button:hover:not(:disabled) {
    background: #54abc9;
}

/* Mobil nézethez */
@media (max-width: 768px) {
    .card {
        flex: 0 0 100%; /* Mobil nézetben a kártya teljes szélességet kap */
    }
}
/* slider */
